<template>
  <div>
    <!-- <Preloader />
    <Header /> -->
    <Hub />
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Preloader from "@/components/Preloader.vue";
// import Header from "@/components/TheHeader.vue";
// import Footer from "@/components/TheFooter.vue";
import Hub from "@/components/Hub.vue";
export default {
  components: {
    // Preloader,
    // Header,
    Hub,
    // Footer
  }
};
</script>
